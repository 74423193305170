    import { useAxiosPost } from "./api.js";


    export const useAuth = async () =>{
        
        const chatStore = useChatStore()
        const notificationStore = useNotificationStore()
        const projectStore = useProjectStore()
        const statsStore = useStatsStore()
        const supportStore = useSupportStore()
        const tasksStore = useTaskStore()
        const trainingVideoStore = useTrainingStore()
        const walletStore = useWalletStore()
        const store = useUserStore()
        const errorStore = useErrorStore()
        const { unSubscribeChannels } = await useChat()
        const {unSubscribeCommonChannel,subscribeCommonChannel} = await usePusher()

        const resetAllStores = () =>{
            chatStore.resetChatStore()
            notificationStore.resetNotificationStore()
            projectStore.resetProjectStore()
            statsStore.resetStatsStore()
            supportStore.resetSupportStore()
            tasksStore.resetTaskStore()
            trainingVideoStore.resetTrainingVideoStore()
            walletStore.resetWalletStore()
            store.resetUserStore()
            errorStore.resetErrorStore()
        }

        const signIn = async (data) =>{
            try {
                const {data:user,message} = await useAxiosPost('auth/login',data)
                store.setAuth(user.token)
                delete user.token
                store.setProfile(user)
                errorStore.showError(message,true,'success')
                return user
            } catch (error) {
                console.log('signIn Auth Error : ',error)
                errorStore.showError(error.message,true,'error')
            }
            finally{
                subscribeCommonChannel()
            }
        }

        const signUp = async (data) =>{
            try {
                const {data:user,message} = await useAxiosPost('auth/register',data)
                errorStore.showError(message,true,'success')
                return user
            } catch (error) {
                console.log('signUp Auth Error : ',error)
                errorStore.showError(error.message,true,'error')
            }

        }

        const completeSignupRegistraion = async (token,details) => {
            try {
                const {data,message} = await useAxiosPost(`auth/complete-registration/${token}`,details)
                errorStore.showError(message,true,'success')
                return 
            } catch (error) {
                console.log('completeSignupRegistraion Auth Error : ',error)
                errorStore.showError(error.message,true,'error')
            }

        }

        const changePassword = async (data) => {
            try {
                const {response,message} = await useAxiosPost(`auth/change-password`,data)
                errorStore.showError(message,true,'success')
                return 
            } catch (error) {
                console.log('changePassword Auth Error : ',error)
                errorStore.showError(error.message,true,'error')
            }

        }

        const logout = async () => {
            try {
                const {data,message} = await useAxiosPost(`auth/sign-out`,{})
                await unSubscribeChannels()
                await unSubscribeCommonChannel()
                clearInterval(walletStore.intervalId) 
                errorStore.showError(message,true,'success')
            } catch (error) {
                console.log('logout Auth Error : ',error)
                errorStore.showError(error.message,true,'error')
            }
            finally{
                resetAllStores()
                store.removeAuth()
            }

        }

        const logoutAllDevices = async () => {
            try {
                const {data,message} = await useAxiosPost(`auth/sign-out-all`,{})
                await unSubscribeChannels()
                await unSubscribeCommonChannel()
                clearInterval(walletStore.intervalId) 
                errorStore.showError(message,true,'success')
                return 
            } catch (error) {
                console.log('logoutAllDevices Auth Error : ',error)
                errorStore.showError(error.message,true,'error')
            }
            finally{
                resetAllStores()
                store.removeAuth()
            }

        }

        const updateProfile = async (data) => {
            try {
                const {data:user,message} = await useAxiosPatch(`auth/user/update`,data)
                store.setProfile(user)
                errorStore.showError(message,true,'success')
                return 
            } catch (error) {
                errorStore.showError(error.message,true,'error')
                throw error
            }
        }

        const uploadProfile = async (formData) => {
            try {
                const {data:res,message} = await useAxiosFormDataPost(`upload?bucket="avatar"`,formData)
                await updateProfile({profile:res.key})
                errorStore.showError(message,true,'success')
                return 
            } catch (error) {
                console.log('uploadProfile Auth Error : ',error)
                errorStore.showError(error.message,true,'error')
            }
        }

        const googleSignIn = async()=>{
            try {
                const {data:googleUrl,message} = await useAxiosGet('auth/google')
                window.open(googleUrl, '_blank');
                errorStore.showError(message,true,'success')
                return googleUrl
            } catch (error) {
                errorStore.showError(error.message,true,'error')
            }
        }

        const googleRevoke = async()=>{

            try {
                const {data,message} = await useAxiosGet('auth/google-revoke')
                errorStore.showError(message,true,'success')
                return data
            } catch (error) {
                errorStore.showError(error.message,true,'error')
            }
        }

        const getMyProfile = async()=>{
            try {
                const {data:profile,message} = await useAxiosGet('user/me')
                store.setProfile(profile)
                return profile
            } catch (error) {
                console.log('getMyProfile Auth Error : ',error)
                errorStore.showError(error.message,true,'error')
            }
        }

        const forgotPassword = async(email)=>{
            try {
                const {data,message} = await useAxiosPost(`auth/forgot-password`,{email:email})
                errorStore.showError(message,true,'success')
                return 
            } catch (error) {
                console.log('forgotPassword Auth Error : ',error)
                errorStore.showError(error.message,true,'error')
            }
        }

        const resetPassword = async (token, password)=>{
            try {
                const {data,message} = await useAxiosPost(`auth/reset-password`,{token:token,password:password})
                errorStore.showError(message,true,'success')
                return 
            } catch (error) {
                console.log('resetPassword Auth Error : ',error)
                errorStore.showError(error.message,true,'error')
            }
        }

        return {
            getMyProfile,
            signIn,
            signUp,
            completeSignupRegistraion,
            changePassword,
            logout,
            logoutAllDevices,
            updateProfile,
            uploadProfile,
            googleSignIn,
            googleRevoke,
            resetAllStores,
            forgotPassword,
            resetPassword
        }
    }
